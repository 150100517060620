import React from "react";
import logos from "./logo.png";
import "../Footer/Footer.css";
import { Link, NavLink } from 'react-router-dom';

function footer() {
  return (
    <> <div className="contain-1">
      <div className="footer">
        <NavLink to="/"><img className="footer-img" src={logos} alt="" /></NavLink>
      </div>
      <div className="footer">
        <h4>QUICK LINKS</h4>
      
          <li> <Link to="/"> Home</Link></li>
          <li> <Link to="/about">About Us </Link></li>
          <li><Link to="/portfolio"> Portfolio</Link></li>
          <li><Link to="/services"> Services</Link></li>
          <li> <Link to="/gallery">Gallery</Link></li>
          <li>  <Link to="/team">Team</Link> </li>
          <li><Link to="/contact"> Contact Us</Link></li>
      </div>
      <div className="footer">
        <h4> CONTACT US</h4>
       
        <i className="fa fa-address-card"></i>
          <li className="li-ad">
            <a href="https://maps.app.goo.gl/XRmuqGvj9ErE3Gwx9">Plot No.120 Flate No. G-2 Park View Apartment,<span> lalalajpatray Society</span> <span>  Near by Hanuman Mandir E-7 Arera Colony</span> <span>Bhopal,Madhya Pradesh 462016</span> 
            
            </a>
          </li>
          <i className="fa fa-envelope"></i>
          <li className="li-ad">
            <a href="mailto:maavaishnavientertainments@gmail.com ">maavaishnavientertainments@gmail.com </a>
          </li>
          <i className="fa fa-phone"></i>
          <li  className="li-ad">
         
            <a href="tel:9407271262"> +91-9407271262</a>
          </li>
       
      </div>
      <div className="footer">
        <iframe className="footer-mps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.0619786168927!2d77.43601480000001!3d23.2044118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42436c327607%3A0x1c9518e28ba637!2sMAA%20VAISHNAVI%20ENTERTAINMENTS!5e0!3m2!1sen!2sin!4v1726467333728!5m2!1sen!2sin"></iframe>
      </div>
      <div className="hr-p"><div className="hr"></div></div>

      <div className="tc">
        <div className="tcp"> <p>Design and Developed by <a href="https://techjain.com" target="_blank">Idroid Becance</a></p>
        </div>
      </div>
    </div>
    </>
  ); 
}

export default footer;
