import React, { useState } from "react";
import PropertieeEq from './PropertiesEqImg/Ven.jpg';
import PropertieeEq1 from './PropertiesEqImg/Ven1.jpg';
import PropertieeEq2 from './PropertiesEqImg/Ven2.jpg';
import PropertieeEq3 from './PropertiesEqImg/Ven3.jpg';
import PropertieeEq4 from './PropertiesEqImg/Ven4.jpg';
import PropertieeEq5 from './PropertiesEqImg/Ven5.jpg';
import PropertieeEq6 from './PropertiesEqImg/Ven6.jpg';
import PropertieeEq7 from './PropertiesEqImg/Ven7.jpg';
import PropertieeEq8 from './PropertiesEqImg/WalkyTent.jpg';
import PropertieeEq11 from './PropertiesEqImg/WalkyTent3.jpg';
import PropertieeEq12 from './PropertiesEqImg/WalkyTent4.jpg';
import PropertieeEq13 from './PropertiesEqImg/Catering.jpg';
import PropertieeEq14 from './PropertiesEqImg/Catering1.jpg';
import PropertieeEq15 from './PropertiesEqImg/Catering2.jpg';
import PropertieeEq16 from './PropertiesEqImg/Catering3.jpg';
import PropertieeEq17 from './PropertiesEqImg/Catering4.jpg';
import CloseIcon from '@material-ui/icons/Close';
import "../PropertiesEq/PropertiesEq.css";


const PropertieE = () => {
   let data = [
     {
       id: 1,
       imgSrc: PropertieeEq,
     },
     {
       id: 2,
       imgSrc: PropertieeEq1,
     },
     {
       id: 3,
       imgSrc: PropertieeEq2,
     },
     {
       id: 4,
       imgSrc: PropertieeEq3,
     },
     {
       id: 5,
       imgSrc:PropertieeEq4,
     },
     {
       id: 6,
       imgSrc: PropertieeEq5,
     },
     {
       id: 7,
       imgSrc: PropertieeEq6,
     },
     {
       id: 8,
       imgSrc: PropertieeEq7,
     },
     {
       id: 9,
       imgSrc: PropertieeEq8,
     },
     {
       id: 12,
       imgSrc: PropertieeEq11,
     },
     {
       id: 13,
       imgSrc: PropertieeEq12,
     },
     {
       id: 14,
       imgSrc: PropertieeEq13,
     },
     {
       id: 15,
       imgSrc:PropertieeEq14,
     },
     {
       id: 16,
       imgSrc:PropertieeEq15,
     },
     {
      id: 17,
      imgSrc:PropertieeEq16,
    },
    {
      id: 18,
      imgSrc:PropertieeEq17,
    },
   ]
   const [model, setModel] = useState(false);
   const [tempimgSrc, setTempImgSrc] = useState('');
   const getImg = (imgSrc) => {
     setTempImgSrc(imgSrc);
     setModel(true);
   }
   return (
     <>
       <h1 className="properties-h1">VANITY VAN & WALKY TENT CATERING</h1>
       <div className={model ? "model open" : "model"}>
         <img src={tempimgSrc} />
         <CloseIcon onClick={() => setModel(false)} />
       </div>
       <div className="galleryLocat">
         {data.map((item, index) => {
           return (
             <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
               {/* <h1>Location</h1> */}
               <img className="properties" src={item.imgSrc} style={{ width: '100%', height: '35vh' }} />
             </div>
           )
 
         })}
       </div>
     </>
   )
 }
 export default PropertieE;