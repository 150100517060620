import React from 'react';
import { Link } from 'react-router-dom';
import Images from "../Services/maavais3.jpg";
import "./Services.css";
function Services() {
  return (
    <> <h1 className="service-h1">LINE PRODUCTION & CASTING</h1>
      <div className='secstion-1'>
        <div className="containers-1">
          <div className="service">
            <p className="service-pss">Line Production Services is the core specialty we Maa Vaishnavi
              Entertainments specialize in, arranging film shoot in Bhopal!
              "Bollywood" is the leading film producer in the world
              Highly skilled production team
              & camera crew, world class post production facilities & movie studios,
              blend with the cutting age technology are easily available at a reasonable cost!
              <br></br>
              We Provide Services
              such as Celebrity Management, Line Production, Casting, Movies Promotion, Events, Official Events & All types of
              Entertainments works.
              
             
             
            </p>
          </div>
          <div className="service"> <a href={Images}>
            <img className="service-img" src={Images} alt="" />
          </a>
          </div>
        </div>
      </div>
      <div className='secstion-2'>
        <div className="containers-3">
          <h1 className="service-h1">Our Services</h1>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Location	Scouting &	Reccee</h2>
              <p> Madhya Pradesh offers some of the most magnificent locations, the land of Khajuraho and Sanchi
                and the jungles of Kanha and Bandhavgarh, the Forts & monuments of Gwalior, Orcha and Mandu,
                The Culture of Bhopal, Indore, Jabalpur, Gwalior, Madhya Pradesh offers Film Makers the most
                diverse Film making experiences! 
              </p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Documentary	Productions</h2>
              <p>Madhya Pradesh is a land of colors and culture diversity, lots of festivals, the different geographical
                region give documentary filmmakers a perfect choice to film in Madhya Pradesh and we can provide
                the full production support while shooting in Madhya Pradesh! it is always a pleasure and honor for all of us here.
              </p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Vendors	Budgeting	&	Scheduling</h2>
              <p>Once we received your scripts or details or storyboards, we will evaluate your total budget &
                requirements and send you the estimates within 24 hours along with guidelines and location
                suggestions!</p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Talent	Casting	Agency</h2>
              <p>Bhupendra Rajput casting Company (BRCC) has most talented actors available. For secondary casting, you don't have to bring them that save a lot of budgets!</p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Corporate	/	Industrial	Filmmakers</h2>
              <p>Maa Vaishnavi Entertainments can make corporate film or industrial video for your company that can boost the company a
                corporate image! We provide one-stop production solution to your corporate identity!</p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Celebrity	Management	&	Brand	Endorsement</h2>
              <p>If you need any celebrity for functions, showroom opening, brand or product endorsement we have a
                direct approach to most of Bollywood celebrity who can appear to you.</p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Purchase	and	Procurement</h2>
              <p>Maa Vaishnavi Entertainments will act as a one-stop solution for all your purchase &
                 procurement from a nail to helicopter.
                 For secondary casting, you don't have to bring them that save a lot of budgets!</p>
            </div>
          </div>
          <div className="ser">
            <div className='secstion-3'>
              <h2 className="services1-h1">Filming	Permits	Madhya	Pradesh</h2>
              <p>Production companies, ad agencies or corporate filmmakers planning to shoot your films, video or
                TV commercials project anywhere in Madhya Pradesh! We can organize filming permits as per your
                locations needs!</p>
            </div>
          </div>
        </div>
        <div className="containers-3">
          <div className='secstion-4'>
          <h1 className="view-h1">OUR VIEWS</h1>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>CITY</h2>
                <Link to="/city">View more</Link>
             </div>
            </div>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>LOCATION</h2>
                <Link to="/location">View more</Link>
              </div>
            </div>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>WEB SERIES </h2>
                <Link to="/webseries">View more</Link>
              </div>
            </div>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>PROPERTIES </h2>
                <Link to="/properties">View more</Link>
              </div>
            </div>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>MOVIES</h2>
                <Link to="/moviecasting">View more</Link>
              </div>
            </div>
            <div className="cords-4">
              <div className="f-d-inst">
                <h2>LIGHTS</h2>
                <Link to="/lights">View more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Services;

