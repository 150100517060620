import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import img1 from "./img34.jpg";
import img2 from "./img5.jpg";
import img3 from "./img10.jpg";
import img4 from "./img33.jpg";
import img5 from "./img18.jpg";
import img6 from "./img39.jpg";
import img7 from "./img6.jpg";
import img8 from "./img8.jpg";
import img9 from "./img19.jpg";
import img10 from "./img32.jpg";
import img11 from "./img6.jpg";
import { NavLink } from 'react-router-dom';

import "./Gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Slide() {

    return (
        <><h1 className="about-h1">GALLERY</h1>
        <div className="gallery-section">
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Celebrity</h2>
                        < NavLink to="/celebrity">View More</ NavLink>
                    </div>
                </div>
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Office</h2>
                        < NavLink to="/office">View More</ NavLink>
                    </div>
                </div>
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Our Works</h2>
                        < NavLink to="/serial">View More</ NavLink>
                    </div>
                </div>
            </div>
            <div className='contenar'>
                <div className="main-slide">
                    <Carousel >
                        <div>
                            <img src={img1} />
                        </div>

                        <div>
                            <img src={img2} />
                        </div>

                        <div>
                            <img src={img3} />
                        </div>
                        <div>
                            <img src={img4} />
                        </div>

                        <div>
                            <img src={img5} />
                        </div>
                        <div>
                            <img src={img6} />
                        </div>
                       
                        <div>
                            <img src={img9} />
                        </div>
                        <div>
                            <img src={img10} />
                        </div>
                        <div>
                            <img src={img11} />
                        </div>
                        <div>
                            <img src={img8} />
                        </div>

                    </Carousel>
                </div>
            </div>
            
        </>
    )
}
