import React, { Component } from "react";
import Sliders from "react-slick";
import card1 from "./im1.jpg";
import card2 from "./im2.jpg";
import card3 from "./im3.jpg";
import card4 from "./im4.jpg";
import card5 from "./im5.jpg";
import card6 from "./im6.jpg";
import card7 from "./im7.jpg";
import card8 from "./im8.jpg";
import card9 from "./im9.jpg";
import "../Cardslider/Sliders.css";
export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed:2000,
      autoplaySpeed: 2000,
      cssEase: "linear",
    };
    return (
      <div className="cards">
        <div className="base">
        <h1 className="poster-slider-h1">OUR TOP MOVIES</h1>
          <Sliders {...settings} className="slider">
            <div className="card-div">
              <img src={card1} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card2} alt="" />
             
            </div>
            <div className="card-div">
              <img src={card3} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card4} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card5} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card6} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card7} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card8} alt="" />
              
            </div>
            <div className="card-div">
              <img src={card9} alt="" />
              
            </div>
            
          </Sliders>
        </div>
      </div>
    );
  }
}
