import React, { useState } from "react";
import City from "./CityImg/city.jpg";
import City1 from "./CityImg/city1.jpg";
import City2 from "./CityImg/city2.jpg";
import City3 from "./CityImg/city3.jpg";
import City4 from "./CityImg/city4.jpg";
import City5 from "./CityImg/city5.jpg";
import City6 from "./CityImg/city6.jpg";
import City7 from "./CityImg/city7.jpg";
import City8 from "./CityImg/city8.jpg";
import City9 from "./CityImg/city9.jpg";
import City10 from "./CityImg/city10.jpg";
import City11 from "./CityImg/city11.jpg";
import City12 from "./CityImg/city12.jpg";
import City13 from "./CityImg/city13.jpg";
import City14 from "./CityImg/city14.jpg";
import City15 from "./CityImg/city15.jpg";
import CloseIcon from '@material-ui/icons/Close';
import "../City/City.css";

const CityC = () => {
  let data = [
    {
      id: 1,
      imgSrc: City,
      title:"Upper Lake",
    },
    {
      id: 2,
      imgSrc: City1,
      title:"Laxmi Narayan",
    },
    {
      id: 3,
      imgSrc: City2,
      title:"Taj-ul Masjid",
    },
    {
      id: 4,
      imgSrc: City3,
      title:"Shaukat Mahal",
      
    },
    {
      id: 5,
      imgSrc:City4,
      title:"City Garden",
    },
    {
      id: 6,
      imgSrc: City5,
      title:"Moti Masjid",
    },
    {
      id: 7,
      imgSrc: City6,
      title:"State Archaeological",
    },
    {
      id: 8,
      imgSrc: City7,
      title:"Bharat Bhawan",
    },
    {
      id: 9,
      imgSrc: City8,
      title:"Upper Lake",
    },
    {
      id: 10,
      imgSrc: City9,
      title:"Lower Lake",
    },
    {
      id: 11,
      imgSrc: City10,
      title:"Upper Lake",
    },
    {
      id: 12,
      imgSrc: City11,
      title:"Taj Mahal",
    },
    {
      id: 13,
      imgSrc: City12,
      title:"Bhopal Lake",
    },
    {
      id: 14,
      imgSrc: City13,
      title:"Javeri Temple",
    },
    {
      id: 15,
      imgSrc:City14,
      title:"Panch Marhi",
    },
    {
      id: 16,
      imgSrc:City15,
      title:"National Park",
    },
  ]
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <>
     <h1 className="city-h1">CITY SIGHTSEEING</h1>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="galleryLocat">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
              {/* <h1>Location</h1> */}
              <img className="City" src={item.imgSrc} style={{ width: '100%', height: '35vh' }} />
               <h4 className="city-head">{item.title}</h4>
            </div>
          )

        })}
      </div>
    </>
  )
}
export default CityC;