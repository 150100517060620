import React from "react";
import YouTubeIcon from './youtube.png';
import InstagramIcon from './instagram.png';
import FacebookIcon from './facebook.png';
import TwitterIcon from './twitter.png';
import Whatsapp from './whatsapp.png';
import "../SocialMedia/SocialFollow.css";
import { Link } from "react-router-dom";

export default function SocialFollow() {
  return (
    <>
      <div className="social-container">
        <a href="https://wa.me/919407271262" target='_blank'
          className="whatsapp social" >
          <img src={Whatsapp} alt="" />
        </a>
        <a
          href=" https://m.facebook.com/maavaishnavientertainments/" target='_blank'
          className="facebook social"
        >
          <img src={FacebookIcon} alt="" />
        </a>
        <a
          href="https://www.instagram.com/invites/contact/?i=1mycdewl7y69y&utm_content=khvan5d" target='_blank'
          className="instagram social"
        >
          <img src={InstagramIcon} alt="" />
        </a>
        <a
          href="https://lm.facebook.com/l.php?u=https%3A%2F%2Fyoutube.com%2Fchannel%2FUCpxrYcgNe3fJ-BzpngGlslQ%3Ffbclid%3DIwAR0VenPkVa-YVzNxkZw892-HHzeLLy4Zwf425hrZA9K0VJLlm99NS7Q84SE&h=AT1zHkZ2hUahaw_6XGqVAqDJhMUMI3f5GIgtghaf_JyW81HxagVESCGteZzKLoWSFqRhK3AK6ijWVySQnCe9FN4kf7R_ovVlyq96y168nz5iWQi87LkbK9R-IOmbhzMWPI9mVMEtdTBVgwD4qD2hLyMl2mck6WJydAWmNryWun8NhAgv1kDablFjRAhbR-73wdeIfID5bfHwQitKKm-wHFyVqsWDOdOFge3k8WsSr4E4_6tIFkc9FHTNYBNWtoItLmzmHRpvN-tJ4dVn2j-LwRDMfeT_nesCtVhCBh6U0wAgwvT3IloS1CYQE_oyknlzUJe6laUZbI4iEt_VLZygHRKTzAatkwFBNVL11meWwMaYy_YF6OeKx1k6an2iNf8exSQLz_bDUYzCLwtG5SFh2Isv3AMlk-hPQB-vLDVCNrk-OSB_wqEA8uqFb7pYRDh0YwC9tk8hNU4oZJbIL1Fp--lfG7hX6OsVrU0NtmqIk-aa4cW_4GcIfhicIcQvXOr0bZ8GBrQrGISE-LizsGbiymFZx-Phxr1l0_MImw-kdtoasW-7-m0qKeJcHjVCxYeMMNbkh6bwxNViAe_DbvP3ODWICmAqxgx3AmTTq7lymHtooeaS6Pi9URHPyGD8EVcotI_2jLlR-hfJE5X6NblxIS7cGWL5LRglJz79tSRXYsp2C5BvjnFogFWqjj2rSOs1XRiquNcY6RLSAyQ " target='_blank'
          className="youtube social"
        >
          <img src={YouTubeIcon} alt="" />
        </a>
      </div>
      <div className="getquote">
        <button className="getquote-button"><Link to="/contact"> Get quote</Link></button>
      </div>
    </>
  );
}
