import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; 
import pic1 from "./imgs.png";
import pic2 from "./img2.jpg";
import pic3 from "./img3.jpg";
import pic4 from "./img4.jpg";
import pic5 from "./img5.jpg";
import pic6 from "./img6.jpg";
import pic9 from "./golarg.png";
import pic10 from "./logo.png";
import AddTaskIcon from "@material-ui/icons/Movie";
import TheatersIcon from "@material-ui/icons/Theaters";
import MovieCreationIcon from "@material-ui/icons/MovieCreation";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../Slider/Slider.css";
import { Link } from "react-router-dom";
export default function Slider() {
  return (
    <>
      <div className="HomeContainer">
        <div className="heding">
          <h2 className="heding-h2">MAA VAISHNAVI Entertainments,<br></br> BHOPAL  MADHYA PRADESH
          </h2>
          <p>A Complete Line Production Company in M.P., U.P. ,Goa And All Over india</p>
        </div>
        <div className="Carousel1">
          <div className="child">
            <Carousel
              autoPlay={true}
              showThumbs={false}
              infiniteLoop={true}
              interval={3000}
              showArrows={false}
              showStatus={false}
            >
              <div className="Slide">
                <img src={pic4} alt="" />
              </div>
              <div className="Slide">
                <img src={pic5} alt="" />
              </div>
              <div className="Slide">
                <img src={pic3} alt="" />
              </div>
              <div className="Slide">
                <img src={pic6} alt="" />
              </div>
              <div className="Slide">
                <img src={pic2} alt="" />
              </div>
              <div className="Slide">
                <img src={pic1} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      {/* <------------------Carousal----------------------------------> */}

      {/* <------------------Intro----------------------------------> */}
      <div className="main-container">
        <div className="container">
          <div className="slider-card">
            <img className="card-img" style={{ width: "90%" }} src={pic1} alt="" />
            
          </div>
          <div className="slider-card">
            <h4 className="card-intro">OUR INTRODUCTION</h4>
            <h2 className="card-h2">LET'S MAKE GREAT THINGS TOGETHER</h2>
            <p className="card-p-f"> Maa Vaishnavi  Entertainments providing  cords for Line Production  & Casting in Bhopal Madhya Pradesh since 2010
              We have strong experience of around 14 years in providing end to end Film & Television Industry relevant solutions, Working Professionals and Corporate...
              <br></br>
              We are successfully implementing idea of integrating several individual units within it to cater to every simple need to enhance clients satisfaction through cost controlled productive turnkey solutions.
              We have very strong network of sub vendors , suppliers and third party outsourcing partners in different part of Madhya Pradesh.
              <br></br> <button className="card-btn"><Link to="/about"> Read More</Link></button>
            </p>
          </div>
        </div>
      </div>
      {/* <------------------features----------------------------------> */}
      <div className="main-container-1">
        <div className="container-1">
          <div className="slider-card-1">
            <h4 className="card-intro">CORE FEATURES</h4>
            <h2 className="card-h2">BEST FEATURES  THAT WON'T DISAPPOINT</h2>
          </div>
          <div className="slider-card-1">
            <p className="card-p">We provide Location, Equipments, card and Crew
              Line Producer,
              Director Of Photography,
              Costume Designers,
              Choreographers,
              Art Director,
              Unit Production Managers,
              Location Scouts,
              Production Assistants,
              1st Assistant Director,
              Assistant Cameraman,
              Camera Operators,
              Gaffer.</p>
          </div>
        </div>
        <div className="container-2">
          <div className="slider-card-2">
            <div className="card-icon">
              <AddTaskIcon />
            </div>
            <h4 className="card-h4-u">PROJECT</h4>
            <p className="card-p">
              Bring your story to life with our industry-leading platform. Share your project with us , access our network of over one million performers and make your masterpiece.
              Projects can be completely contained within a specific unit or
              department, or include other organizations and vendors.

            </p>
          </div>
          <div className="slider-card-2">
            <div className="card-icon">
              <MovieCreationIcon />
            </div>
            <h4 className="card-h4-u">TALENT</h4>
            <p className="card-p">
              Get cast in your next project. Share your photos, reel and resume, then search thousands of commercial, television and theatrical
              projects and submit yourself for more roles than anywhere else.
              share Your Profile : Showcase your headshots, and more talent. 
              
            </p>
          </div>
          <div className="slider-card-2">
            <div className="card-icon">
              <TheatersIcon />
            </div>
            <h4 className="card-h4-u">BEST CHOOSE</h4>
            <p className="card-p">
              Film & video production companies , ad agencies, film directors,
              documentary & corporate filmmakers looking for local Indian line Producer in the country
              of colours & culturbal diversity,
              anywhere in the world, locations like Gohar Mahel, VIP road in city of lakes.
            </p>
          </div>
        </div>
      </div>
      {/* <------------------features----------------------------------> */}
      {/* <------------------films----------------------------------> */}
      <div className="main-container-2">
        <div className="containers-3">
          <div className="slider-card-3">
            <h4 className="card-intro">TYPE OF SERVICES </h4>
            <h2 className="card-h2">CHOOSE THE TYPE OF SERVICES YOU LIKE</h2>
            <p className="card-p">Line Production card is the core specialty we Maa Vaishnavi Entertainments  specialize in, arranging film shoot in Bhopal! "Bollywood" is the leading film producer in the world, highly skilled production team & camera crew, world class post production facilities & movie studios,
              blend with the cutting age technology are easily available at a reasonable cost! 
              <br></br> <button className="view-btn"><Link to="/services"> View More</Link></button>
            </p>
          </div>
          <div className="slider-card-3">
            <div className="containers-4"> 
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>CITY</h3>
                  <Link to="/city">View more</Link>
                </div>
              </div>
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>LOCATION</h3>
                  <Link to="/location">View more</Link>
                </div>
              </div>
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>WEB SERIES </h3>
                  <Link to="/webseries">View more</Link>
                </div>
              </div>
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>PROPERTIES </h3>
                  <Link to="/properties">View more</Link>
                </div>
              </div>
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>MOVIES</h3>
                  <Link to="/moviecasting">View more</Link>
                </div>
              </div>
              <div className="slider-card-4">
                <div className="f-d-inst">
                  <h3>LIGHTS</h3>
                  <Link to="/lights">View more</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containers-5">
          <div className="slider-card-5">
            <h2 className="card-h2-p">Casting Agency</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Location Scouting & Recce</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Filming Permits Madhya Pradesh</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Vendor Budgeting & Scheduling</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Purchase and Procurement</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Documentary Productions</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Corporate/Industrial Filmmakers</h2>
          </div>
          <div className="slider-card-5">
            <h2 className="card-h2-p">Celebrity Management & Brand Endorsement</h2>
          </div>
        </div>
      </div>

      {/* <------------------films----------------------------------> */}

      {/* <------------------Quotes----------------------------------> */}
    </>
  );
}