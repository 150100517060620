import React from "react";
import PropertieeEq18 from '../Lights/li.jpg';
import PropertieeEq19 from '../Lights/li1.jpg';
import PropertieeEq20 from '../Lights/li2.jpg';
import PropertieeEq21 from '../Lights/li3.jpg';
import "../Lights/Lights.css";

export default function Lights() {
 return (
  <>  
  <div className="container7">
   <div className="equipment">
   <h1 className="box-h1">LIGHTS</h1>
     <div className="box">
      <img className="box-img" src={PropertieeEq19} alt="" />
      <h2>	 ARRILUX PAR LIGHT</h2>
      <p>
       Arrilux 400 "pocket par",
       Arrilux 200 "pocket par",
       Arrilux 125 "pocket par" &
       Alpha 4k.
      </p>
     </div>
     <div className="box">
      <img className="box-img" src={PropertieeEq20} alt="" />
      <h2>DINO PAR 64 BULBS
      </h2>
      <p>
       Dino 24 bank with dimmer
       Par cans 1000 watt,
       Par cans 500 watt ,
       Par cans 300 watt.

      </p>
     </div>
     <div className="box">
      <img  className="box-img" src={PropertieeEq21} alt="" />
      <h2>FRESNEL ARRI TUNGSTEN 
      </h2>
      <p>
       Arri studio T-24 ,
       Arri true blue T-5,
       Arri true blue T-2,
       ARRI true blue T-1,
       ARRI STUDIO T-10.
      </p>
     </div>
     <div className="box">
      <img className="box-img" src={PropertieeEq18} alt="" />
      <h2>HMI DAYLIGHT FRENSEL</h2>
      <p>
       Arri daylight 18/12,
       Arri compact 12000,
       Arri compact 6000,
       Arri true blue D40,
       Arri true blue D25,
       Arri true blue D12,
       Arri true blue D5.
      </p>
     </div>
    </div>
   </div>
  </>

 );
}