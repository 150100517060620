import React from "react";
import "./Career.css";
import dir from "./dir.jpeg"

export default function Career() {
    return (<>
        <h1 className="about-h1">JOIN OUR TEAM</h1>

        <div className="c-head">

            <div className="car-parent">
                {/* <div className="car-child1">
                    <img src={dir} />
                </div> */}
                <div className="car-child2">
                    <h4 className="ja">JOB ALERT...</h4>
                    <h2 className="jah">Maa Vaishnavi Entertainments Film Production 🎥 </h2>
                    <h4 className="hiring">We Are Hiring :-</h4>
                    <ul className="ulist">
                        <p className="p-list"><li>Hr Manager</li></p>
                        <p className="p-list">  <li> Production Assistant</li></p>
                        <p className="p-list">   <li>Casting Associate</li></p>
                        <p className="p-list"> <li>Casting Assistant</li></p>
                    </ul>
                    <p className="sal">Note:- Salary will be based as per skills and experience <br /> ( any one can apply but familiar and have some basic knowledge of Film Industry) <br />Hurry up guys!!!!!!!!!</p>
                    <h4 className="hiring">Contact Us :-</h4>
                    <p className="contact-p">Email:- <a href="mailto:maavaishnavientertainments@gmail.com"> maavaishnavientertainments@gmail.com</a></p>
                    <p className="contact-p">Whatsapp No:- <a href="https://wa.me/919407271262" target='_blank' >9407271262</a> <br />
                        (Strictly no phone calls📱 whatsapp only)</p>
                </div>
            </div>

        </div>
    </>)

}