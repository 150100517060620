import React, { useState } from "react";
import Img1 from "./PortfolioImg/im.jpg";
import Img2 from "./PortfolioImg/im1.jpg";
import Img3 from "./PortfolioImg/im2.jpg";
import Img4 from "./PortfolioImg/im3.jpg";
import Img5 from "./PortfolioImg/im4.jpg";
import Img6 from "./PortfolioImg/im5.jpg";
import Img7 from "./PortfolioImg/im6.jpg";
import Img8 from "./PortfolioImg/im7.jpg";
import Img9 from "./PortfolioImg/im8.jpg";
import Img10 from "./PortfolioImg/im9.jpg";
import Img11 from "./PortfolioImg/im10.jpg";
import Img12 from "./PortfolioImg/im11.jpg";
import "./Portfolio.css";
import CloseIcon from '@material-ui/icons/Close';


const Portfolio = () => {
  let data = [
    {
      id: 2,
      imgSrc: Img1,
    },
    {
      id: 3,
      imgSrc: Img2,
    },
    {
      id: 4,
      imgSrc: Img3,
    },
    {
      id: 5,
      imgSrc: Img4,
    },
    {
      id: 6,
      imgSrc: Img5,
    },
    {
      id: 7,
      imgSrc: Img6,
    },
    {
      id: 8,
      imgSrc: Img7,
    },
    {
      id: 9,
      imgSrc: Img8,
    },
    {
      id: 10,
      imgSrc: Img9,
    },
    {
      id: 11,
      imgSrc: Img10,
    },
    {
      id: 12,
      imgSrc: Img11,
    },
    {
      id: 13,
      imgSrc: Img12,
    },
    
  ]
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <>
     <h1 className="Portfolio-h1">ACHIEVEMENT WE HAVE</h1>
 
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="galleryPortfolio">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
              {/* <h1>Portfolioion</h1> */}
              <img src={item.imgSrc} style={{ width: '95%', height: '30vh' }} />
            </div>
          )
        })}
      </div>
    </>
  )
}
export default Portfolio;