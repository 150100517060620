 import React from "react";
import { useState } from "react";
import "../Contact/Contact.css";
import FeatherIcon from "feather-icons-react";
// import PhoneInput from 'react-phone-number-input'

function Contact() {

  const [data, setData] = useState({ username: "", email: "", phone: "", message: "" });
  const { username, email, phone, message } = data;

  const inputEvent = (e) => {
    const { value, name } = e.target;

    // setData((preValue) => {
    //   return {
    //     ...preValue,
    //     [name]: value,
    //   };
    setData({ ...data, [name]: value });

  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // alert(`My fisrt name is ${data.username} phone no is ${data.phone} email is ${data.email} msg is ${data.message}`);
    try {
      const response = await fetch('https://v1.nocodeapi.com/snymahto/google_sheets/nOyIitfhskwhFPBJ?tabId=Sheet1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify([[username, email, phone, message, new Date().toLocaleString()],]),
      });
      await response.json();
      setData({ ...data, username: "", email: "", phone: "", message: "" })
    }
    catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <h1 className="contact-h1">CONTACT US</h1>
      <div class="container1">
        {/* <div class="form"> */}
        <div class="contact-info">
          {/* <div className="title1"><h3>LET'S GET IN TOUCH</h3></div> */}

          <div className="text"><p>
          

            <h4><FeatherIcon icon="map-pin" /> MAA VAISHNAVI ENTERTAINMENTS</h4>
            Address:- Plot No. 120 Flat No. G-2 Park View Apartment,
             Lalalajpatray Society  Near by Hanuman Mandir E-7 Arera Colony
           Bhopal, <br></br>   Madhya Pradesh 462016 India
         
          </p></div>

          <div class="information">
            <div><h4><FeatherIcon icon="phone" /> Phone No:</h4></div>
            <div><a href="tel:9407271262"><p>9407271262 </p></a></div>
          </div>
          <div class="information">
            <h4><FeatherIcon icon="mail" />  Email:</h4>
            <a href="mailto:maavaishnavientertainments@gmail.com"><p>maavaishnavientertainments@gmail.com</p></a>
          </div>




          <div className="map">
            <h4><FeatherIcon icon="map-pin" /> Office Address</h4>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733583.5656761434!2d76.17866864447515!3d23.957692378667684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39667381d35aea05%3A0xe0106b0d4e701c1e!2sMadhya%20Pradesh!5e0!3m2!1sen!2sin!4v1649054985017!5m2!1sen!2sin"
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
               <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.0619786168927!2d77.43601480000001!3d23.2044118!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c42436c327607%3A0x1c9518e28ba637!2sMAA%20VAISHNAVI%20ENTERTAINMENTS!5e0!3m2!1sen!2sin!4v1726467333728!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
         
        </div>


        <div class="contact-form">
          <form onSubmit={onSubmit}>
            <h3 class="title">GET IN TOUCH</h3>

            <div class="input-container">
              <input type="text" name="username" value={data.username} class="input" placeholder="Username" onChange={inputEvent} autoComplete="off" required/>
            </div>
            <div class="input-container">
              <input type="email" name="email" value={data.email} class="input" placeholder="Email" onChange={inputEvent} autoComplete="off" required/>

            </div>
            <div class="input-container">
              < input type="text" name="phone" value={data.phone} class="input" placeholder="Phone" onChange={inputEvent} autoComplete="off" maxLength={10} required/>

            </div>
            <div class="input-container textarea">
              <textarea name="message" class="input" value={data.message} placeholder="Message" onChange={inputEvent} autoComplete="off" required></textarea>

            </div>
            <button type="submit" class="btn" required>Send</button>
          </form>
        </div>

      </div>
    </>
  );
}

export default Contact;
