import React from "react";
import pic3 from "./founder.png";
import '../Team/Team.css';

export default function OurTeam() {
  return (
    <>

      <div className="main-container-c">
        <h1 className="about-h1">OUR TEAM</h1>
           <div className="card_div">
        <div className="o-child">
          <div className="card-container-c">
            <div className="card1">
              <div className="img_cont"><img clas src={pic3} alt="" />
                <h2 className="discrip">Mr Bhupendra Rajput</h2>
              </div>
              <div className="info">
                <h3 className="head2">Founder</h3>
                <p className="paragraph">
                  The heart of Maa Vaishnavi Entertainments. Brimming with energy and “I can do anything” attitude, this one is the go getter who would stop at nothing till he ticks every check box for his clients. Driven and passionate and doing the most gruelling tasks with a smile is his USP. He started working as a teenager and knows what it takes to get to the top. A cinematographer at heart, he has worked as Assistant DOP on various projects.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* /* card2 */}
        {/* <div className="o-child">
          <div className="card-container-c">
            <div className="card1">
              <div className="img_cont"><img src={pic1} alt="" />
                <h2 className="discrip">Abhishek Singh Rajput</h2></div>
              <div className="info">

                <h3 className="head2">Manager</h3>
                <p className="paragraph">
                As a manager of the organization responsible for developing and carrying out this management process.
                 The  primary functions of managers are planning, organizing, leading, and controlling. He is 
                 responsible for a particular function or department within the organization.

                </p>
              </div>
            </div>
          </div>
        </div> */}
        </div>
        
        
      </div>
    </>
  );
}
