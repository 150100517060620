import React from "react";
import "../About/About.css";
import Image from "../About/maavais.jpg"; 

function About() {
  return (
    <>
 <div className="containers-1">
      <h1 className="about-h1">ABOUT US</h1>
      <div className="about"> 
      <p className="about-p">An honest and transparent organization supporting growth of Film
          & TV artists in Bhopal Madhya Pradesh, providing platform
          for upcoming and budding newcomers, comprising of systematically
          working professionals with decades of experience. May this
          organization grow leaps and bounds consecutive. Maa Vaishnavi
          Entertainments (Company) a one stop solution For All.. 
           <br></br>We provide
          Entertainments activities such As: Casting and Line Production
          For Movies & TV, Celebrity Management,
          Movies Promotion, Events, Official Events & All types of
          Entertainments works..</p>
      </div>
      <div className="about"> <a href={Image}>
        <img className="about-img" src={Image} alt="" />
      </a>
      </div>
      </div>
      <div className="containers-1">
      <div className="abouts1"> 
      <h2 className="abouts-1-h1">PRODUCTION SERVICES</h2>
            <p className="abouts1-p">
              We source the right multilingual crew & technicians for the job.
              We have access to state of the art film equipment,
              production design & studio spaces.
            </p>
      </div>
      <div className="abouts1"> 
      <h2 className="abouts-1-h1">POST PRODUCTION </h2>
            <p className="abouts1-p">
              We work with the finest post production houses in the region.
              We provide offline and online editing,
              special effects, animation, dubbing, sound engineering and music.
            </p>
      </div>
      <div className="abouts1">
      <h2 className="abouts-1-h1">PRE PRODUCTION SERVICES</h2>
            <p className="abouts1-p">
              We give productions a head start by providing in-depth research and transparent budgeting.
              We then assist in permits, location scouting, multicity casting, travel & visas.
            </p>
      </div>
      </div>
      </>
 );
 }
 export default About;
 