import React from 'react';
import "./App.css";
import Navbar from './components/Navbar/Navbar';
import Slider from './components/Slider/Slider';
import About from './components/About/About';
import Services from './components/Services/Services';
import Portfolio from './components/Portfolio/Portfolio';
import Team from './components/Team/Team';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Sliders from './components/Cardslider/Sliders';
import { Routes, Route } from "react-router-dom";
import SocialFollow from './components/SocialMedia/SocialFollow';
import City from './components/City/City';
import Location from './components/Location/Location';
import MovieCasting from './components/MovieCasting/MovieC';
import Properties from './components/PropertiesEq/PropertiesEq';
import MovieC from './components/WebSeriesC/WebseriesC';
import Lights from './components/Lights/Lights';
import Office from './components/Office-gal/Office';
import Celebrity from './components/Celebrity-gal/Celebrity';
import Serial from './components/Serial-gal/Serial';
import Career from './components/Career/Career';

function App() {
  return (
    <>
      <div className="App">
        <Navbar />
        <SocialFollow />
        <Routes> 
          <Route exact path="/" element={<Slider />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/city" element={<City />} />
          <Route exact path="/location" element={<Location />} />
          <Route exact path="/moviecasting" element={<MovieCasting />} />
          <Route exact path="/properties" element={<Properties />} />
          <Route exact path="/webseries" element={<MovieC />} />
          <Route exact path="/Office" element={<Office />} />
          <Route exact path="/Celebrity" element={<Celebrity />} />
          <Route exact path="/Serial" element={<Serial />} />
          <Route exact path="/lights" element={<Lights />} />
          <Route exact path="/career" element={<Career />} />
          
        </Routes>
        <Sliders />
        {/* <PhotoAni/> */}
        <Footer />
      </div>
    </>
  );
}
export default App;
