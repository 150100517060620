import React, { useState } from "react";
import Webseries from "./WebImg/webs.jpg";
import Webseries1 from "./WebImg/webs1.jpg";
import Webseries2 from "./WebImg/webs2.jpg";
import Webseries3 from "./WebImg/webs3.jpg";
// import Webseries4 from "./WebImg/webs4.jpg";
import Webseries5 from "./WebImg/webs5.jpg";
import Webseries6 from "./WebImg/webs6.jpg";
import Webseries7 from "./WebImg/webs7.jpg";
import  Webseries8 from "./WebImg/tvSe.jpg";
import Webseries9 from "./WebImg/tvSe1.jpg";
import Webseries10 from "./WebImg/tvSe2.jpg";
import Webseries11 from "./WebImg/tvSe3.jpg";
import  Webseries12 from "./WebImg/tvSe4.jpg";
import  Webseries13 from "./WebImg/tvSe5.jpg";
import  Webseries14 from "./WebImg/tvSe6.jpg";
import Webseries15 from "./WebImg/tvSe7.jpg";
import CloseIcon from '@material-ui/icons/Close';
import "../WebSeriesC/WebseriesC.css";

const WebseriesC = () => {
    let data = [
      {
        id: 1,
        imgSrc: Webseries,
      },
      {
        id: 2,
        imgSrc: Webseries1,
      },
      {
        id: 3,
        imgSrc: Webseries2,
      },
      {
        id: 4,
        imgSrc: Webseries3,
      },
      // {
      //   id: 5,
      //   imgSrc:Webseries4,
      // },
      {
        id: 6,
        imgSrc: Webseries5,
      },
      {
        id: 7,
        imgSrc: Webseries6,
      },
      {
        id: 8,
        imgSrc: Webseries7,
      },
      {
        id: 9,
        imgSrc: Webseries8,
      },
      {
        id: 10,
        imgSrc: Webseries9,
      },
      {
        id: 11,
        imgSrc: Webseries10,
      },
      {
        id: 12,
        imgSrc: Webseries11,
      },
      {
        id: 13,
        imgSrc: Webseries12,
      },
      {
        id: 14,
        imgSrc: Webseries13,
      },
      {
        id: 15,
        imgSrc:Webseries14,
      },
      {
        id: 16,
        imgSrc:Webseries15,
      },
    ]
    const [model, setModel] = useState(false);
    const [tempimgSrc, setTempImgSrc] = useState('');
    const getImg = (imgSrc) => {
      setTempImgSrc(imgSrc);
      setModel(true);
    }
    return (
      <>
        <h1 className="Webseries-h1">WEB SERIES</h1>
        <div className={model ? "model open" : "model"}>
          <img src={tempimgSrc} />
          <CloseIcon onClick={() => setModel(false)} />
        </div>
        <div className="galleryLocat">
          {data.map((item, index) => {
            return (
              <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                {/* <h1>Location</h1> */}
                <img className="Webseries" src={item.imgSrc} style={{ width: '100%', height: '35vh' }} />
              </div>
            )
  
          })}
        </div>
      </>
    )
  }
  export default WebseriesC;