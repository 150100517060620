import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import img1 from "./gallery1.png";
import img2 from "./gallery2.png";
import img3 from "./gallery3.png";
import img4 from "./gallery4.png";
import img5 from "./gallery5.png";
import img6 from "./gallery6.png";
import img7 from "./gallery7.png";
import img8 from "./gallery8.png";
import { NavLink } from 'react-router-dom';


import "./Office.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Office() {

    return (
        <><h1 className="about-h1">GALLERY</h1>
        <div className="gallery-section">
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Celebrity</h2>
                        < NavLink to="/celebrity">View More</ NavLink>
                    </div>
                </div>
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Office</h2>
                        < NavLink to="/office">View More</ NavLink>
                    </div>
                </div>
                <div className="section">
                    <div className="wrap-div">
                        <h2 className='gal-head'>Our Works</h2>
                        < NavLink to="/serial">View More</ NavLink>
                    </div>
                </div>
            </div>
            <div className='contenar'>
                <div className="main-slide">
                    <Carousel className='c-container' >
                        <div>
                            <img src={img1} />
                        </div>

                        <div>
                            <img src={img2} />
                        </div>

                        <div>
                            <img src={img3} />
                        </div>
                        <div>
                            <img src={img4} />
                        </div>

                        <div>
                            <img src={img5} />
                        </div>
                        <div>
                            <img src={img6} />
                        </div>
                        <div>
                            <img src={img7} />
                        </div>
                        <div>
                            <img src={img8} />
                        </div>
                       

                    </Carousel>
                </div>
            </div>
            

        </>
    )
}
