import React, { useState } from "react";
import Img from "./LocationImg/Location.jpg";
import Img1 from "./LocationImg/Location1.jpg";
import Img2 from "./LocationImg/Location2.jpg";
import Img3 from "./LocationImg/Location3.jpg";
import Img4 from "./LocationImg/Location6.jpg";
import Img5 from "./LocationImg/Location4.jpg";
import Img6 from "./LocationImg/Location7.jpg";
import Img7 from "./LocationImg/Location8.jpg";
import Img8 from "./LocationImg/Location9.jpg";
import Img9 from "./LocationImg/Location10.jpg";
import Img10 from "./LocationImg/Location11.jpg";
import Img11 from "./LocationImg/Location12.jpg";
import Img12 from "./LocationImg/Location13.jpg";
import Img13 from "./LocationImg/Location14.jpg";
import Img14 from "./LocationImg/Location15.jpg";
import Img15 from "./LocationImg/Location16.jpg";
import Img16 from "./LocationImg/Location17.jpg";
import Img17 from "./LocationImg/Location18.jpg";
import Img18 from "./LocationImg/Location19.jpg";
import Img19 from "./LocationImg/Location20.jpg";
import CloseIcon from '@material-ui/icons/Close';
// import CloseIcon from '@mui/icons-material/Close';

import "./Location.css";

const Location = () => {
  let data = [
    {
      id: 1,
      imgSrc: Img,
      title:"Jungle Resort",
    },
    {
      id: 2,
      imgSrc: Img1,
      title:"Ancient Hotel",
    },
    {
      id: 3,
      imgSrc: Img2,
      title:"White Hotel",
    },
    {
      id: 4,
      imgSrc: Img3,
      title:"Kitchen",
    },
    {
      id: 5,
      imgSrc: Img4,
      title:"Wood Hotel",
    },
    {
      id: 6,
      imgSrc: Img5,
      title:"National Park",
    },
    {
      id: 7,
      imgSrc: Img6,
      title:"Wood Restro",
    },
    {
      id: 8,
      imgSrc: Img7,
      title:"Brick Mahal",
    },
    {
      id: 9,
      imgSrc: Img8,
      title:"White House",
    },
    {
      id: 10,
      imgSrc: Img9,
      title:"City Garden",
    },
    {
      id: 11,
      imgSrc: Img10,
      title:"Window Hotel",
    },
    {
      id: 12,
      imgSrc: Img11,
      title:"White Resourt",
    },
    {
      id: 13,
      imgSrc: Img12,
      title:"White Apartment",
    },
    {
      id: 14,
      imgSrc: Img13,
      title:"Green Hotel",
    },
    {
      id: 15,
      imgSrc: Img14,
      title:"White Hotel",
    },
    {
      id: 16,
      imgSrc: Img15,
      title:"Green Garden",
    },
    {
      id: 17,
      imgSrc: Img16,
      title:"Light House",
    },
    {
      id: 18,
      imgSrc: Img17,
      title:"Wooden Restro",
    },
    {
      id: 19,
      imgSrc: Img18,
      title:"Light Hotel",
    },
    {
      id: 19,
      imgSrc: Img19,
      title:"Green Resort",
    },
  ]
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <>
      <h1 className="location-h1">LOCATION</h1>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="galleryLocat">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
              
              <img src={item.imgSrc} className="pic-class" style={{ width: '100%', height: '40vh' }} />
              <h4 className="locatio-head">{item.title}</h4>
            </div>
          )

        })}
      </div>
    </>
  )
}
export default Location;