import React, { useState } from "react";
import Img from "./MovieImg/im.jpg";
import Img1 from "./MovieImg/im1.jpg";
import Img2 from "./MovieImg/im2.jpg";
import Img3 from "./MovieImg/im3.jpg";
import Img4 from "./MovieImg/im6.jpg";
import Img5 from "./MovieImg/im4.jpg";
import Img6 from "./MovieImg/im7.jpg";
import Img7 from "./MovieImg/im8.jpg";
import Img8 from "./MovieImg/im9.jpg";
import Img9 from "./MovieImg/im10.jpg";
import Img10 from "./MovieImg/im11.jpg";
import Img11 from "./MovieImg/im12.jpg";
import Img12 from "./MovieImg/im13.jpg";
import Img13 from "./MovieImg/im14.jpg";
import Img14 from "./MovieImg/im15.jpg";
import Img15 from "./MovieImg/im5.jpg";
import CloseIcon from '@material-ui/icons/Close';

import "./MovieC.css";

const Movie = () => {
  let data = [
    {
      id: 1,
      imgSrc: Img,
    },
    {
      id: 2,
      imgSrc: Img1,
    },
    {
      id: 3,
      imgSrc: Img2,
    },
    {
      id: 4,
      imgSrc: Img3,
    },
    {
      id: 5,
      imgSrc: Img4,
    },
    {
      id: 6,
      imgSrc: Img5,
    },
    {
      id: 7,
      imgSrc: Img6,
    },
    {
      id: 8,
      imgSrc: Img7,
    },
    {
      id: 9,
      imgSrc: Img8,
    },
    {
      id: 10,
      imgSrc: Img9,
    },
    {
      id: 11,
      imgSrc: Img10,
    },
    {
      id: 12,
      imgSrc: Img11,
    },
    {
      id: 13,
      imgSrc: Img12,
    },
    {
      id: 14,
      imgSrc: Img13,
    },
    {
      id: 15,
      imgSrc: Img14,
    },
    {
      id: 16,
      imgSrc: Img15,
    },
  
  ]
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState('');
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  }
  return (
    <>
      <h1 className="Movie-h1">MOVIE CASTING</h1>
      <div className={model ? "model open" : "model"}>
        <img src={tempimgSrc} />
        <CloseIcon onClick={() => setModel(false)} />
      </div>
      <div className="galleryMovie">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
              {/* <h1>Movieion</h1> */}
              <img className="movie" src={item.imgSrc} style={{ width: '100%', height: '35vh' }} />
            </div>
          )

        })}
      </div>
    </>
  )
}
export default Movie;
